import React, { useState } from 'react'

const SelectInput = props => {
  const [displayOptions, setDisplayOptions] = useState(false)
  const onSelect = () => {
    setDisplayOptions(!displayOptions)
  }
  const onChange = key => {
    onSelect()
    props.onChange(key)
  }

  return <div className='select-input'>
    <div className='input' onClick={onSelect}>
      {props.value ? props.value : props.placeholder}
    </div>
    {
      displayOptions
        ? <div className='select-data'>
          {
            props.data.map((item, index) => <div className='select-item' key={index}>
              <span onClick={() => onChange(item.key)} >{item.key}</span>
            </div>)
          }
        </div>
        : null
    }
  </div>
}

export default SelectInput