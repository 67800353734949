import React from "react";

const SubInfo = (props) => {
  let { periods = [], cost, lang, cost_2, isScholarship } = props;
  const options = {
    month: "long",
    day: "numeric",
  };

  if (isScholarship) {
    periods = [
      ...periods,
      null,
      {
        title: {
          es: "Fecha límite",
          en: "Deadline",
        },
        subtitle: {
          es: 'La convocatoria se abrirá a partir de mayo de 2024',
          en: 'The open call will be starting May 2024',
        },
        startDate: "2024-05-01",
        endDate: "2024-05-30",
        status: "active",
        label: {
          es: "Convocatoria cerrada",
          en: "Closed call",
        },
      },
    ];
  }

  const type = lang === "es" ? "es-MX" : "en-US";
  let priceLabel = lang === "es" ? "1 artista: " : "Solo project: ";
  let buttonOff = ''
  if (isScholarship) {
    priceLabel = lang === "es" ? "Subsidiado" : "Subsidized";
    buttonOff = "off"
  }


  return (
    <div className="subinfo-container">
      <div className="dates-container">
        {periods.map((date, index) =>
          date === null ? (
            <div key={index} className={`date-item`} />
          ) : (
            <div key={index} className={`date-item ${date.status}`}>
              <h2>{date.title[lang]}</h2>
              <p>{date.subtitle[lang]}</p>
              {date.label && date.label[lang] ? (
                <div className="fulldate-container">
                  <span>{date.label[lang]}</span>
                </div>
              ) : (
                <div className="fulldate-container">
                  {lang === "es" ? "Del" : "From"}
                  <span>
                    {" "}
                    {new Date(date.startDate).toLocaleDateString(type, options)}
                    {lang === "es" ? " al" : " to"}{" "}
                    {new Date(date.endDate).toLocaleDateString(type, {
                      ...options,
                      year: "numeric",
                    })}{" "}
                  </span>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <div className="subinfo-buttons">
        <div className="cost full-background-contain">
          <div className="cost-info">
            <span className="title">{lang === "es" ? "COSTO" : "TUITION"}</span>
            <span className="subtitle">
              {lang === "es" ? "3 semanas" : "3 weeks"}
            </span>
            <span className="price">
              {priceLabel} {cost}
            </span>
            {typeof cost_2 === "string" && Boolean(cost_2.trim().length) && (
              <span className="price">
                {lang === "es" ? "2 artistas" : "Duo project"}: {cost_2}
              </span>
            )}
          </div>
        </div>
        <div className={"apply-btn " + buttonOff}>
          <div className="btn" onClick={props.onApply}>
            <span className="icon icon-double-chevron-right" />
            {lang === "es" ? "Aplicar" : "Apply"}
            <span className="icon icon-double-chevron-left" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubInfo;

/*
let { } = props.data

*/
