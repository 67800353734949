import React, { useState, useRef, useEffect, memo } from "react";
import { Slide } from "react-slideshow-image";
import YouTube from "react-youtube";
import VideoPlayer from "../../baseComponents/VideoPlayer";

import coordsPng from "../../assets/imgs/home/coordenadas.png";
import element01 from "../../assets/imgs/home/element_01.png";
import element02 from "../../assets/imgs/home/element_02.png";
import element03 from "../../assets/imgs/home/element_03.png";

import "./index.css";
import "react-slideshow-image/dist/styles.css";

const Home = (props) => {
  let { home, programas } = props.data;
  const { show = "0" } = home.message;
  const { pageIndex, onChangePage, onChangeProgram = () => null } = props;
  const [isHoverScrollBtn, setIsHoverScrollBtn] = useState(""),
    [autoplay, setAutoplay] = useState(true),
    [slideIndex, setSlideIndex] = useState(0),
    [lastPage, setLastPage] = useState(0),
    { lang } = props,
    slideRef = useRef();
  const onMouseEnter = () => setIsHoverScrollBtn("active");
  const onMouseLeave = () => setIsHoverScrollBtn("");
  const onChangeSlide = (oldIndex, newIndex) => setSlideIndex(newIndex);
  const goto = (index) => {
    setSlideIndex(index);
    slideRef.current.goTo(index);
    setAutoplay(true);
  };

  useEffect(() => {
    if (lastPage !== pageIndex) {
      if (pageIndex === 0) return setAutoplay(true);
      setAutoplay(false);
      setSlideIndex(0);
      if (slideRef.current) slideRef.current.goTo(0);
    }
    // eslint-disable-next-line
  }, [pageIndex]);

  const properties = {
    autoplay: autoplay,
    duration: 5000,
    arrows: false,
    transitionDuration: 1,
  };

  let slides = null;
  let id = Object.keys(programas);

  id = id[id.length - 1];

  if (home.images.length === 1) {
    let item = home.images[0];
    if (item.type === "video") {
      slides = (
        <VideoPlayer
          isFirst={true}
          url={item.url}
          isVisible={pageIndex === 0}
          onPlay={() => setAutoplay(false)}
          poster={item.image}
        />
      );
    } else {
      slides = (
        <div
          className="slide-image"
          style={{ background: `url(${item.url})` }}
        />
      );
    }
  } else {
    slides = home.images.map((slide, i) => {
      if (slide.type === "video") {
        return (
          <VideoPlayer
            key={i}
            isFirst={i === 0}
            url={slide.url}
            isVisible={i === slideIndex && pageIndex === 0}
            onPlay={() => setAutoplay(false)}
            poster={slide.image}
          />
        );
      }
      return (
        <div
          key={i}
          className="slide-image"
          style={{ background: `url(${slide.url})` }}
        />
      );
    });
  }

  return (
    <div id="home" className="home-mobile">
      <div className="home-content">
        <div className="home-title">
          <div>
            <h1>Cobertizo</h1>
            <span>
              {lang === "es" ? "Residencia de arte" : "Art Residency"}
            </span>
          </div>
        </div>
        <div className="home-slider">
          {Boolean(show !== "0") && (
            <div
              onClick={() => {
                onChangeProgram(id);
                setTimeout(() => {
                  onChangePage(2);
                }, 500);
              }}
              className="home-popup animated"
            >
              <div className="scroll">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span style="width: 5rem;"></span>${home.message[lang]}<span style="width: 10vw;"></span>`.repeat(
                        4
                      ),
                  }}
                ></p>
              </div>
            </div>
          )}
          {home.images.length > 1 ? (
            <Slide
              canSwipe={false}
              onChange={onChangeSlide}
              className="slider"
              ref={slideRef}
              {...properties}
            >
              {slides}
            </Slide>
          ) : (
            slides
          )}

          <div className="slider-buttons">
            {Boolean(home.images.length > 1) &&
              home.images.map((_, index) => {
                let isActive = index === slideIndex ? "active" : "";
                return (
                  <span
                    key={index}
                    className={`slide-btn ${isActive}`}
                    onClick={() => goto(index)}
                  >
                    {index + 1 < 10 ? `0${index + 1}` : index + 1}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
      <div className="home-scroll">
        <div className="home-image coords-image">
          <img src={coordsPng} alt="coordenadas" />
        </div>

        <div
          className="scroll-btn"
          onClick={props.onNext}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            style={{ background: `url(${element01})` }}
            className="oblongs full-background-contain"
          />
          <img src={element02} alt="flecha" className="arrow" />
          <span className={`hover ${isHoverScrollBtn}`}>
            {lang === "es" ? "Nosotros" : "Us"}
          </span>
        </div>

        <div className="home-image">
          <img src={element03} alt="figura" />
        </div>
      </div>
    </div>
  );
};

export default memo(Home);
