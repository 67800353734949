export const countriesEs = [
  {
    "value": "Afganistán",
    "key": "Afganistán"
  },
  {
    "value": "Albania",
    "key": "Albania"
  },
  {
    "value": "Alemania",
    "key": "Alemania"
  },
  {
    "value": "Argelia",
    "key": "Argelia"
  },
  {
    "value": "Andorra",
    "key": "Andorra"
  },
  {
    "value": "Angola",
    "key": "Angola"
  },
  {
    "value": "Antigua y Barbuda",
    "key": "Antigua y Barbuda"
  },
  {
    "value": "Antillas Holandesas",
    "key": "Antillas Holandesas"
  },
  {
    "value": "Arabia Saudita",
    "key": "Arabia Saudita"
  },
  {
    "value": "Argentina",
    "key": "Argentina"
  },
  {
    "value": "Armenia",
    "key": "Armenia"
  },
  {
    "value": "Aruba",
    "key": "Aruba"
  },
  {
    "value": "Australia",
    "key": "Australia"
  },
  {
    "value": "Austria",
    "key": "Austria"
  },
  {
    "value": "Azerbaiyán",
    "key": "Azerbaiyán"
  },
  {
    "value": "Bahamas",
    "key": "Bahamas"
  },
  {
    "value": "Bahrein",
    "key": "Bahrein"
  },
  {
    "value": "Bangladesh",
    "key": "Bangladesh"
  },
  {
    "value": "Barbados",
    "key": "Barbados"
  },
  {
    "value": "Bélgica",
    "key": "Bélgica"
  },
  {
    "value": "Belice",
    "key": "Belice"
  },
  {
    "value": "Benín",
    "key": "Benín"
  },
  {
    "value": "Bielorrusia",
    "key": "Bielorrusia"
  },
  {
    "value": "Bolivia",
    "key": "Bolivia"
  },
  {
    "value": "Bosnia y Herzegovina",
    "key": "Bosnia y Herzegovina"
  },
  {
    "value": "Botsuana",
    "key": "Botsuana"
  },
  {
    "value": "Brasil",
    "key": "Brasil"
  },
  {
    "value": "Brunéi",
    "key": "Brunéi"
  },
  {
    "value": "Bulgaria",
    "key": "Bulgaria"
  },
  {
    "value": "Burkina Faso",
    "key": "Burkina Faso"
  },
  {
    "value": "Burundi",
    "key": "Burundi"
  },
  {
    "value": "Bután",
    "key": "Bután"
  },
  {
    "value": "Cabo Verde",
    "key": "Cabo Verde"
  },
  {
    "value": "Camboya",
    "key": "Camboya"
  },
  {
    "value": "Camerún",
    "key": "Camerún"
  },
  {
    "value": "Canadá",
    "key": "Canadá"
  },
  {
    "value": "Chad",
    "key": "Chad"
  },
  {
    "value": "Chile",
    "key": "Chile"
  },
  {
    "value": "China",
    "key": "China"
  },
  {
    "value": "Chipre",
    "key": "Chipre"
  },
  {
    "value": "Colombia",
    "key": "Colombia"
  },
  {
    "value": "Comoras",
    "key": "Comoras"
  },
  {
    "value": "Congo",
    "key": "Congo"
  },
  {
    "value": "Corea del Norte",
    "key": "Corea del Norte"
  },
  {
    "value": "Corea del Sur",
    "key": "Corea del Sur"
  },
  {
    "value": "Costa de Marfil",
    "key": "Costa de Marfil"
  },
  {
    "value": "Costa Rica",
    "key": "Costa Rica"
  },
  {
    "value": "Croacia",
    "key": "Croacia"
  },
  {
    "value": "Cuba",
    "key": "Cuba"
  },
  {
    "value": "Dinamarca",
    "key": "Dinamarca"
  },
  {
    "value": "Yibuti",
    "key": "Yibuti"
  },
  {
    "value": "Ecuador",
    "key": "Ecuador"
  },
  {
    "value": "Egipto",
    "key": "Egipto"
  },
  {
    "value": "El Salvador",
    "key": "El Salvador"
  },
  {
    "value": "Emiratos Árabes Unidos",
    "key": "Emiratos Árabes Unidos"
  },
  {
    "value": "Eritrea",
    "key": "Eritrea"
  },
  {
    "value": "Escocia",
    "key": "Escocia"
  },
  {
    "value": "Eslovaquia",
    "key": "Eslovaquia"
  },
  {
    "value": "Eslovenia",
    "key": "Eslovenia"
  },
  {
    "value": "España",
    "key": "España"
  },
  {
    "value": "Estados Unidos",
    "key": "Estados Unidos"
  },
  {
    "value": "Estonia",
    "key": "Estonia"
  },
  {
    "value": "Etiopía",
    "key": "Etiopía"
  },
  {
    "value": "Feroe, Islas",
    "key": "Feroe, Islas"
  },
  {
    "value": "Filipinas",
    "key": "Filipinas"
  },
  {
    "value": "Finlandia",
    "key": "Finlandia"
  },
  {
    "value": "Fiyi",
    "key": "Fiyi"
  },
  {
    "value": "Francia",
    "key": "Francia"
  },
  {
    "value": "Gabón",
    "key": "Gabón"
  },
  {
    "value": "Gales",
    "key": "Gales"
  },
  {
    "value": "Gambia",
    "key": "Gambia"
  },
  {
    "value": "Georgia",
    "key": "Georgia"
  },
  {
    "value": "Ghana",
    "key": "Ghana"
  },
  {
    "value": "Gibraltar",
    "key": "Gibraltar"
  },
  {
    "value": "Granada",
    "key": "Granada"
  },
  {
    "value": "Grecia",
    "key": "Grecia"
  },
  {
    "value": "Groenlandia",
    "key": "Groenlandia"
  },
  {
    "value": "Guadalupe",
    "key": "Guadalupe"
  },
  {
    "value": "Guatemala",
    "key": "Guatemala"
  },
  {
    "value": "Guernsey",
    "key": "Guernsey"
  },
  {
    "value": "Guinea",
    "key": "Guinea"
  },
  {
    "value": "Guinea Ecuatorial",
    "key": "Guinea Ecuatorial"
  },
  {
    "value": "Guinea-Bissau",
    "key": "Guinea-Bissau"
  },
  {
    "value": "Guyana",
    "key": "Guyana"
  },
  {
    "value": "Haiti",
    "key": "Haiti"
  },
  {
    "value": "Honduras",
    "key": "Honduras"
  },
  {
    "value": "Hong Kong",
    "key": "Hong Kong"
  },
  {
    "value": "Hungría",
    "key": "Hungría"
  },
  {
    "value": "India",
    "key": "India"
  },
  {
    "value": "Indonesia",
    "key": "Indonesia"
  },
  {
    "value": "Inglaterra",
    "key": "Inglaterra"
  },
  {
    "value": "Irak",
    "key": "Irak"
  },
  {
    "value": "Irán",
    "key": "Irán"
  },
  {
    "value": "Irlanda",
    "key": "Irlanda"
  },
  {
    "value": "Isla Pitcairn",
    "key": "Isla Pitcairn"
  },
  {
    "value": "Islandia",
    "key": "Islandia"
  },
  {
    "value": "Islas Salomón",
    "key": "Islas Salomón"
  },
  {
    "value": "Islas Turcas y Caicos",
    "key": "Islas Turcas y Caicos"
  },
  {
    "value": "Islas Virgenes Británicas",
    "key": "Islas Virgenes Británicas"
  },
  {
    "value": "Israel",
    "key": "Israel"
  },
  {
    "value": "Italia",
    "key": "Italia"
  },
  {
    "value": "Jamaica",
    "key": "Jamaica"
  },
  {
    "value": "Japón",
    "key": "Japón"
  },
  {
    "value": "Jersey",
    "key": "Jersey"
  },
  {
    "value": "Jordania",
    "key": "Jordania"
  },
  {
    "value": "Kazajstán",
    "key": "Kazajstán"
  },
  {
    "value": "Kenia",
    "key": "Kenia"
  },
  {
    "value": "Kirguistán",
    "key": "Kirguistán"
  },
  {
    "value": "Kiribati",
    "key": "Kiribati"
  },
  {
    "value": "Kuwait",
    "key": "Kuwait"
  },
  {
    "value": "Laos",
    "key": "Laos"
  },
  {
    "value": "Lesotho",
    "key": "Lesotho"
  },
  {
    "value": "Letonia",
    "key": "Letonia"
  },
  {
    "value": "Líbano",
    "key": "Líbano"
  },
  {
    "value": "Liberia",
    "key": "Liberia"
  },
  {
    "value": "Libia",
    "key": "Libia"
  },
  {
    "value": "Liechtenstein",
    "key": "Liechtenstein"
  },
  {
    "value": "Lituania",
    "key": "Lituania"
  },
  {
    "value": "Luxemburgo",
    "key": "Luxemburgo"
  },
  {
    "value": "Macedonia",
    "key": "Macedonia"
  },
  {
    "value": "Madagascar",
    "key": "Madagascar"
  },
  {
    "value": "Malasia",
    "key": "Malasia"
  },
  {
    "value": "Malawi",
    "key": "Malawi"
  },
  {
    "value": "Maldivas",
    "key": "Maldivas"
  },
  {
    "value": "Malí",
    "key": "Malí"
  },
  {
    "value": "Malta",
    "key": "Malta"
  },
  {
    "value": "Man, Isla de",
    "key": "Man, Isla de"
  },
  {
    "value": "Marruecos",
    "key": "Marruecos"
  },
  {
    "value": "Martinica",
    "key": "Martinica"
  },
  {
    "value": "Mauricio",
    "key": "Mauricio"
  },
  {
    "value": "Mauritania",
    "key": "Mauritania"
  },
  {
    "value": "México",
    "key": "México"
  },
  {
    "value": "Moldavia",
    "key": "Moldavia"
  },
  {
    "value": "Mónaco",
    "key": "Mónaco"
  },
  {
    "value": "Mongolia",
    "key": "Mongolia"
  },
  {
    "value": "Mozambique",
    "key": "Mozambique"
  },
  {
    "value": "Myanmar",
    "key": "Myanmar"
  },
  {
    "value": "Namibia",
    "key": "Namibia"
  },
  {
    "value": "Nauru",
    "key": "Nauru"
  },
  {
    "value": "Nepal",
    "key": "Nepal"
  },
  {
    "value": "Nicaragua",
    "key": "Nicaragua"
  },
  {
    "value": "Níger",
    "key": "Níger"
  },
  {
    "value": "Nigeria",
    "key": "Nigeria"
  },
  {
    "value": "Norfolk Island",
    "key": "Norfolk Island"
  },
  {
    "value": "Noruega",
    "key": "Noruega"
  },
  {
    "value": "Nueva Caledonia",
    "key": "Nueva Caledonia"
  },
  {
    "value": "Nueva Zelanda",
    "key": "Nueva Zelanda"
  },
  {
    "value": "Omán",
    "key": "Omán"
  },
  {
    "value": "Países Bajos, Holanda",
    "key": "Países Bajos, Holanda"
  },
  {
    "value": "Pakistán",
    "key": "Pakistán"
  },
  {
    "value": "Palestina",
    "key": "Palestina"
  },
  {
    "value": "Panamá",
    "key": "Panamá"
  },
  {
    "value": "Papúa-Nueva Guinea",
    "key": "Papúa-Nueva Guinea"
  },
  {
    "value": "Paraguay",
    "key": "Paraguay"
  },
  {
    "value": "Perú",
    "key": "Perú"
  },
  {
    "value": "Polinesia Francesa",
    "key": "Polinesia Francesa"
  },
  {
    "value": "Polonia",
    "key": "Polonia"
  },
  {
    "value": "Portugal",
    "key": "Portugal"
  },
  {
    "value": "Puerto Rico",
    "key": "Puerto Rico"
  },
  {
    "value": "Qatar",
    "key": "Qatar"
  },
  {
    "value": "República Checa",
    "key": "República Checa"
  },
  {
    "value": "República Democrática del Congo",
    "key": "República Democrática del Congo"
  },
  {
    "value": "República Dominicana",
    "key": "República Dominicana"
  },
  {
    "value": "Reunión",
    "key": "Reunión"
  },
  {
    "value": "Ruanda",
    "key": "Ruanda"
  },
  {
    "value": "Rumanía",
    "key": "Rumanía"
  },
  {
    "value": "Rusia",
    "key": "Rusia"
  },
  {
    "value": "Sáhara Occidental",
    "key": "Sáhara Occidental"
  },
  {
    "value": "Samoa",
    "key": "Samoa"
  },
  {
    "value": "San Cristobal y Nevis",
    "key": "San Cristobal y Nevis"
  },
  {
    "value": "San Marino",
    "key": "San Marino"
  },
  {
    "value": "San Pedro y Miquelón",
    "key": "San Pedro y Miquelón"
  },
  {
    "value": "San Tomé y Príncipe",
    "key": "San Tomé y Príncipe"
  },
  {
    "value": "San Vincente y Granadinas",
    "key": "San Vincente y Granadinas"
  },
  {
    "value": "Santa Elena",
    "key": "Santa Elena"
  },
  {
    "value": "Santa Lucía",
    "key": "Santa Lucía"
  },
  {
    "value": "Senegal",
    "key": "Senegal"
  },
  {
    "value": "Serbia y Montenegro",
    "key": "Serbia y Montenegro"
  },
  {
    "value": "Seychelles",
    "key": "Seychelles"
  },
  {
    "value": "Sierra Leona",
    "key": "Sierra Leona"
  },
  {
    "value": "Singapur",
    "key": "Singapur"
  },
  {
    "value": "Siria",
    "key": "Siria"
  },
  {
    "value": "Somalia",
    "key": "Somalia"
  },
  {
    "value": "Sri Lanka",
    "key": "Sri Lanka"
  },
  {
    "value": "Sudáfrica",
    "key": "Sudáfrica"
  },
  {
    "value": "Sudán",
    "key": "Sudán"
  },
  {
    "value": "Suecia",
    "key": "Suecia"
  },
  {
    "value": "Suiza",
    "key": "Suiza"
  },
  {
    "value": "Surinam",
    "key": "Surinam"
  },
  {
    "value": "Swazilandia",
    "key": "Swazilandia"
  },
  {
    "value": "Tadjikistan",
    "key": "Tadjikistan"
  },
  {
    "value": "Tailandia",
    "key": "Tailandia"
  },
  {
    "value": "Taiwan",
    "key": "Taiwan"
  },
  {
    "value": "Tanzania",
    "key": "Tanzania"
  },
  {
    "value": "Timor Oriental",
    "key": "Timor Oriental"
  },
  {
    "value": "Togo",
    "key": "Togo"
  },
  {
    "value": "Tokelau",
    "key": "Tokelau"
  },
  {
    "value": "Tonga",
    "key": "Tonga"
  },
  {
    "value": "Trinidad y Tobago",
    "key": "Trinidad y Tobago"
  },
  {
    "value": "Túnez",
    "key": "Túnez"
  },
  {
    "value": "Turkmenistan",
    "key": "Turkmenistan"
  },
  {
    "value": "Turquía",
    "key": "Turquía"
  },
  {
    "value": "Tuvalu",
    "key": "Tuvalu"
  },
  {
    "value": "Ucrania",
    "key": "Ucrania"
  },
  {
    "value": "Uganda",
    "key": "Uganda"
  },
  {
    "value": "Uruguay",
    "key": "Uruguay"
  },
  {
    "value": "Uzbekistán",
    "key": "Uzbekistán"
  },
  {
    "value": "Vanuatu",
    "key": "Vanuatu"
  },
  {
    "value": "Venezuela",
    "key": "Venezuela"
  },
  {
    "value": "Vietnam",
    "key": "Vietnam"
  },
  {
    "value": "Wallis y Futuna",
    "key": "Wallis y Futuna"
  },
  {
    "value": "Yemen",
    "key": "Yemen"
  },
  {
    "value": "Zambia",
    "key": "Zambia"
  },
  {
    "value": "Zimbabwe",
    "key": "Zimbabwe"
  }
]

export const countriesEn = [
  {
    "value": "Afghanistan",
    "key": "Afghanistan"
  },
  {
    "value": "Albania",
    "key": "Albania"
  },
  {
    "value": "Algeria",
    "key": "Algeria"
  },
  {
    "value": "Andorra",
    "key": "Andorra"
  },
  {
    "value": "Angola",
    "key": "Angola"
  },
  {
    "value": "Antigua and Barbuda",
    "key": "Antigua and Barbuda"
  },
  {
    "value": "Argentina",
    "key": "Argentina"
  },
  {
    "value": "Armenia",
    "key": "Armenia"
  },
  {
    "value": "Australia",
    "key": "Australia"
  },
  {
    "value": "Austria",
    "key": "Austria"
  },
  {
    "value": "Azerbaijan",
    "key": "Azerbaijan"
  },
  {
    "value": "Bahamas",
    "key": "Bahamas"
  },
  {
    "value": "Bahrain",
    "key": "Bahrain"
  },
  {
    "value": "Bangladesh",
    "key": "Bangladesh"
  },
  {
    "value": "Barbados",
    "key": "Barbados"
  },
  {
    "value": "Belarus",
    "key": "Belarus"
  },
  {
    "value": "Belgium",
    "key": "Belgium"
  },
  {
    "value": "Belize",
    "key": "Belize"
  },
  {
    "value": "Benin",
    "key": "Benin"
  },
  {
    "value": "Bhutan",
    "key": "Bhutan"
  },
  {
    "value": "Bolivia",
    "key": "Bolivia"
  },
  {
    "value": "Bosnia and Herzegovina",
    "key": "Bosnia and Herzegovina"
  },
  {
    "value": "Botswana",
    "key": "Botswana"
  },
  {
    "value": "Brazil",
    "key": "Brazil"
  },
  {
    "value": "Brunei",
    "key": "Brunei"
  },
  {
    "value": "Bulgaria",
    "key": "Bulgaria"
  },
  {
    "value": "Burkina Faso",
    "key": "Burkina Faso"
  },
  {
    "value": "Burundi",
    "key": "Burundi"
  },
  {
    "value": "Cambodia",
    "key": "Cambodia"
  },
  {
    "value": "Cameroon",
    "key": "Cameroon"
  },
  {
    "value": "Canada",
    "key": "Canada"
  },
  {
    "value": "Cape Verde",
    "key": "Cape Verde"
  },
  {
    "value": "Czech Republic",
    "key": "Czech Republic"
  },
  {
    "value": "Central African Republic",
    "key": "Central African Republic"
  },
  {
    "value": "Chad",
    "key": "Chad"
  },
  {
    "value": "Chile",
    "key": "Chile"
  },
  {
    "value": "China",
    "key": "China"
  },
  {
    "value": "Colombia",
    "key": "Colombia"
  },
  {
    "value": "Comoros",
    "key": "Comoros"
  },
  {
    "value": "Congo",
    "key": "Congo"
  },
  {
    "value": "Costa Rica",
    "key": "Costa Rica"
  },
  {
    "value": "Croatia",
    "key": "Croatia"
  },
  {
    "value": "Cuba",
    "key": "Cuba"
  },
  {
    "value": "Cyprus",
    "key": "Cyprus"
  },
  {
    "value": "Denmark",
    "key": "Denmark"
  },
  {
    "value": "Djibouti",
    "key": "Djibouti"
  },
  {
    "value": "Dominican Republic",
    "key": "Dominican Republic"
  },
  {
    "value": "East Timor",
    "key": "East Timor"
  },
  {
    "value": "Ecuador",
    "key": "Ecuador"
  },
  {
    "value": "Egypt",
    "key": "Egypt"
  },
  {
    "value": "El Salvador",
    "key": "El Salvador"
  },
  {
    "value": "Equatorial Guinea",
    "key": "Equatorial Guinea"
  },
  {
    "value": "Eritrea",
    "key": "Eritrea"
  },
  {
    "value": "Estonia",
    "key": "Estonia"
  },
  {
    "value": "Ethiopia",
    "key": "Ethiopia"
  },
  {
    "value": "Faroe Islands",
    "key": "Faroe Islands"
  },
  {
    "value": "Fiji",
    "key": "Fiji"
  },
  {
    "value": "Finland",
    "key": "Finland"
  },
  {
    "value": "France",
    "key": "France"
  },
  {
    "value": "Gabon",
    "key": "Gabon"
  },
  {
    "value": "Gambia",
    "key": "Gambia"
  },
  {
    "value": "Georgia",
    "key": "Georgia"
  },
  {
    "value": "Germany",
    "key": "Germany"
  },
  {
    "value": "Ghana",
    "key": "Ghana"
  },
  {
    "value": "Gibraltar",
    "key": "Gibraltar"
  },
  {
    "value": "Greece",
    "key": "Greece"
  },
  {
    "value": "Greenland",
    "key": "Greenland"
  },
  {
    "value": "Grenada",
    "key": "Grenada"
  },
  {
    "value": "Guadeloupe",
    "key": "Guadeloupe"
  },
  {
    "value": "Guatemala",
    "key": "Guatemala"
  },
  {
    "value": "Guinea",
    "key": "Guinea"
  },
  {
    "value": "Guinea-Bissau",
    "key": "Guinea-Bissau"
  },
  {
    "value":

 "Guyana",
    "key": "Guyana"
  },
  {
    "value": "Haiti",
    "key": "Haiti"
  },
  {
    "value": "Honduras",
    "key": "Honduras"
  },
  {
    "value": "Hong Kong",
    "key": "Hong Kong"
  },
  {
    "value": "Hungary",
    "key": "Hungary"
  },
  {
    "value": "Iceland",
    "key": "Iceland"
  },
  {
    "value": "India",
    "key": "India"
  },
  {
    "value": "Indonesia",
    "key": "Indonesia"
  },
  {
    "value": "Iran",
    "key": "Iran"
  },
  {
    "value": "Iraq",
    "key": "Iraq"
  },
  {
    "value": "Ireland",
    "key": "Ireland"
  },
  {
    "value": "Isle of Man",
    "key": "Isle of Man"
  },
  {
    "value": "Israel",
    "key": "Israel"
  },
  {
    "value": "Italy",
    "key": "Italy"
  },
  {
    "value": "Jamaica",
    "key": "Jamaica"
  },
  {
    "value": "Japan",
    "key": "Japan"
  },
  {
    "value": "Jordan",
    "key": "Jordan"
  },
  {
    "value": "Kazakhstan",
    "key": "Kazakhstan"
  },
  {
    "value": "Kenya",
    "key": "Kenya"
  },
  {
    "value": "Kiribati",
    "key": "Kiribati"
  },
  {
    "value": "Kuwait",
    "key": "Kuwait"
  },
  {
    "value": "Kyrgyzstan",
    "key": "Kyrgyzstan"
  },
  {
    "value": "Laos",
    "key": "Laos"
  },
  {
    "value": "Latvia",
    "key": "Latvia"
  },
  {
    "value": "Lebanon",
    "key": "Lebanon"
  },
  {
    "value": "Lesotho",
    "key": "Lesotho"
  },
  {
    "value": "Liberia",
    "key": "Liberia"
  },
  {
    "value": "Libya",
    "key": "Libya"
  },
  {
    "value": "Liechtenstein",
    "key": "Liechtenstein"
  },
  {
    "value": "Lithuania",
    "key": "Lithuania"
  },
  {
    "value": "Luxembourg",
    "key": "Luxembourg"
  },
  {
    "value": "Macedonia",
    "key": "Macedonia"
  },
  {
    "value": "Madagascar",
    "key": "Madagascar"
  },
  {
    "value": "Malawi",
    "key": "Malawi"
  },
  {
    "value": "Malaysia",
    "key": "Malaysia"
  },
  {
    "value": "Maldives",
    "key": "Maldives"
  },
  {
    "value": "Mali",
    "key": "Mali"
  },
  {
    "value": "Malta",
    "key": "Malta"
  },
  {
    "value": "Martinique",
    "key": "Martinique"
  },
  {
    "value": "Mauritania",
    "key": "Mauritania"
  },
  {
    "value": "Mauritius",
    "key": "Mauritius"
  },
  {
    "value": "Mexico",
    "key": "Mexico"
  },
  {
    "value": "Moldova",
    "key": "Moldova"
  },
  {
    "value": "Monaco",
    "key": "Monaco"
  },
  {
    "value": "Mongolia",
    "key": "Mongolia"
  },
  {
    "value": "Montenegro",
    "key": "Montenegro"
  },
  {
    "value": "Morocco",
    "key": "Morocco"
  },
  {
    "value": "Mozambique",
    "key": "Mozambique"
  },
  {
    "value": "Myanmar",
    "key": "Myanmar"
  },
  {
    "value": "Namibia",
    "key": "Namibia"
  },
  {
    "value": "Nauru",
    "key": "Nauru"
  },
  {
    "value": "Nepal",
    "key": "Nepal"
  },
  {
    "value": "Netherlands",
    "key": "Netherlands"
  },
  {
    "value": "New Caledonia",
    "key": "New Caledonia"
  },
  {
    "value": "New Zealand",
    "key": "New Zealand"
  },
  {
    "value": "Nicaragua",
    "key": "Nicaragua"
  },
  {
    "value": "Niger",
    "key": "Niger"
  },
  {
    "value": "Nigeria",
    "key": "Nigeria"
  },
  {
    "value": "North Korea",
    "key": "North Korea"
  },
  {
    "value": "Norway",
    "key": "Norway"
  },
  {
    "value": "Oman",
    "key": "Oman"
  },
  {
    "value": "Pakistan",
    "key": "Pakistan"
  },
  {
    "value": "Palestine",
    "key": "Palestine"
  },
  {
    "value": "Panama",
    "key": "Panama"
  },
  {
    "value": "Papua New Guinea",
    "key": "Papua New Guinea"
  },
  {
    "value": "Paraguay",
    "key": "Paraguay"
  },
  {
    "value": "Peru",
    "key": "Peru"
  },
  {
    "value": "Philippines",
    "key": "Philippines"
  },
  {
    "value": "Poland",
    "key": "Poland"
  },
  {
    "value": "Portugal",
    "key": "Portugal"
  },
  {
    "value": "Puerto Rico",
    "key": "Puerto Rico"
  },
  {
    "value": "Qatar",
    "key": "Qatar"
  },
  {
    "value": "Reunion",
    "key": "Reunion"
  },
  {
    "value": "Romania",
    "key": "Romania"
  },
  {
    "value": "Russia",
    "key": "Russia"


  },
  {
    "value": "Rwanda",
    "key": "Rwanda"
  },
  {
    "value": "Saint Helena",
    "key": "Saint Helena"
  },
  {
    "value": "Saint Kitts and Nevis",
    "key": "Saint Kitts and Nevis"
  },
  {
    "value": "Saint Lucia",
    "key": "Saint Lucia"
  },
  {
    "value": "Saint Pierre and Miquelon",
    "key": "Saint Pierre and Miquelon"
  },
  {
    "value": "Saint Vincent and the Grenadines",
    "key": "Saint Vincent and the Grenadines"
  },
  {
    "value": "Samoa",
    "key": "Samoa"
  },
  {
    "value": "San Marino",
    "key": "San Marino"
  },
  {
    "value": "Sao Tome and Principe",
    "key": "Sao Tome and Principe"
  },
  {
    "value": "Saudi Arabia",
    "key": "Saudi Arabia"
  },
  {
    "value": "Scotland",
    "key": "Scotland"
  },
  {
    "value": "Senegal",
    "key": "Senegal"
  },
  {
    "value": "Serbia and Montenegro",
    "key": "Serbia and Montenegro"
  },
  {
    "value": "Seychelles",
    "key": "Seychelles"
  },
  {
    "value": "Sierra Leone",
    "key": "Sierra Leone"
  },
  {
    "value": "Singapore",
    "key": "Singapore"
  },
  {
    "value": "Slovakia",
    "key": "Slovakia"
  },
  {
    "value": "Slovenia",
    "key": "Slovenia"
  },
  {
    "value": "Solomon Islands",
    "key": "Solomon Islands"
  },
  {
    "value": "Somalia",
    "key": "Somalia"
  },
  {
    "value": "South Africa",
    "key": "South Africa"
  },
  {
    "value": "South Korea",
    "key": "South Korea"
  },
  {
    "value": "Spain",
    "key": "Spain"
  },
  {
    "value": "Sri Lanka",
    "key": "Sri Lanka"
  },
  {
    "value": "Sudan",
    "key": "Sudan"
  },
  {
    "value": "Suriname",
    "key": "Suriname"
  },
  {
    "value": "Swaziland",
    "key": "Swaziland"
  },
  {
    "value": "Sweden",
    "key": "Sweden"
  },
  {
    "value": "Switzerland",
    "key": "Switzerland"
  },
  {
    "value": "Syria",
    "key": "Syria"
  },
  {
    "value": "Taiwan",
    "key": "Taiwan"
  },
  {
    "value": "Tajikistan",
    "key": "Tajikistan"
  },
  {
    "value": "Tanzania",
    "key": "Tanzania"
  },
  {
    "value": "Thailand",
    "key": "Thailand"
  },
  {
    "value": "Togo",
    "key": "Togo"
  },
  {
    "value": "Tokelau",
    "key": "Tokelau"
  },
  {
    "value": "Tonga",
    "key": "Tonga"
  },
  {
    "value": "Trinidad and Tobago",
    "key": "Trinidad and Tobago"
  },
  {
    "value": "Tunisia",
    "key": "Tunisia"
  },
  {
    "value": "Turkey",
    "key": "Turkey"
  },
  {
    "value": "Turkmenistan",
    "key": "Turkmenistan"
  },
  {
    "value": "Turks and Caicos Islands",
    "key": "Turks and Caicos Islands"
  },
  {
    "value": "Tuvalu",
    "key": "Tuvalu"
  },
  {
    "value": "Uganda",
    "key": "Uganda"
  },
  {
    "value": "Ukraine",
    "key": "Ukraine"
  },
  {
    "value": "United Arab Emirates",
    "key": "United Arab Emirates"
  },
  {
    "value": "United States",
    "key": "United States"
  },
  {
    "value": "Uruguay",
    "key": "Uruguay"
  },
  {
    "value": "Uzbekistan",
    "key": "Uzbekistan"
  },
  {
    "value": "Vanuatu",
    "key": "Vanuatu"
  },
  {
    "value": "Venezuela",
    "key": "Venezuela"
  },
  {
    "value": "Vietnam",
    "key": "Vietnam"
  },
  {
    "value": "Wales",
    "key": "Wales"
  },
  {
    "value": "Wallis and Futuna",
    "key": "Wallis and Futuna"
  },
  {
    "value": "Western Sahara",
    "key": "Western Sahara"
  },
  {
    "value": "Yemen",
    "key": "Yemen"
  },
  {
    "value": "Zambia",
    "key": "Zambia"
  },
  {
    "value": "Zimbabwe",
    "key": "Zimbabwe"
  }
];