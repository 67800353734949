import React, { useState, useRef, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'

const Artist = props => {
  let { data, lang } = props
    , images = Object.values(data.imagenes)
    , slideRef = useRef()

  const properties = {
    autoplay: false,
    arrows: false,
    transitionDuration: 1
  }

  const onNext = () => {
    slideRef.current.goNext()
  }
  const onBack = () => {
    slideRef.current.goBack()
  }

  useEffect(() => {
    //props.onLockScroll()
    return () => {
      props.onUnlockScroll()
    }
  }, [])

  return <div id='archive' className='artist page'>
    <div className='artist-slider'>
      <Slide canSwipe={false} className='slider' ref={slideRef} {...properties} >
        {
          images.map((image, index) => {
            return <div key={index} className='full-background-cover' style={{ background: `url(${image})` }}>
            </div>
          })
        }
      </Slide>
      {
        images.length > 1
          ? <div className='slider-buttons'>
            <div className='slider-button-container'>
              <div className='slider-button' onClick={onBack}>
                <span className='icon icon-big-chevron-left' />
                <span className='icon icon-chevron-left' />
              </div>
            </div>
            <div className='slider-button-container'>
              <div className='slider-button' onClick={onNext}>
                <span className='icon icon-big-chevron-right' />
                <span className='icon icon-chevron-right' />
              </div>
            </div>
          </div>
          : null
      }
    </div>
    <div className='artist-content'>
      <div className='back-btn' onClick={props.goBack}>
        <span className='icon icon-big-chevron-left' />
        <span className='icon icon-chevron-left' />
      </div>
      <h1>{data.title}</h1>

      <div className='artist-paragraphs' dangerouslySetInnerHTML={{ __html: data.content[lang] }} />
    </div>
  </div>
}

export default Artist