import React, { memo, useRef, useEffect, useState } from "react";
import YouTube from "react-youtube";

const VideoPlayer = ({
  poster = "",
  isVisible = false,
  url,
  onPlay = () => null,
  isFirst = false,
}) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(isVisible);

  useEffect(() => {
    const onPlayVideo = () => {
      setIsPlaying(true);
      onPlay();
    };
    const onPauseVideo = () => {
      setIsPlaying(false);
    };
    videoRef.current.addEventListener("playing", onPlayVideo);
    videoRef.current.addEventListener("pause", onPauseVideo);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("playing", onPlayVideo);
        videoRef.current.removeEventListener("pause", onPauseVideo);
      }
    };
  }, []);

  useEffect(() => {
    //if (isPlaying && isMuted) setIsMuted(false);
  }, [isPlaying, isMuted]);

  useEffect(() => {
    if (!isVisible && videoRef.current) {
      videoRef.current.pause();
    }
    // eslint-disable-next-line
  }, [isVisible, videoRef]);

  return (
    <div className="slide-image">
      <video
        ref={videoRef}
        disablePictureInPicture
        controlsList="nodownload noremoteplayback noplaybackrate foobar"
        playsInline
        muted={isMuted}
        controls
        poster={poster}
        autoPlay={isVisible}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};

const YoutubePlayer = ({ url, onPlay = () => null }) => {
  return (
    <YouTube
      className="slide-image content-image full-background-cover"
      videoId={url}
      opts={{
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 1,
          muted: 1,
          controls: 0,
        },
      }}
      muted
      onPlay={onPlay}
    />
  );
};

const getYoutubeId = (url = "") => {
  const PATTERN =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const matches = url.match(PATTERN);
  if (matches) return matches[1];
  return false;
};

const Player = ({
  poster = "",
  isVisible = false,
  url,
  onPlay = () => null,
  isFirst = false,
}) => {
  const ytVideoID = getYoutubeId(url);

  if (ytVideoID) return <YoutubePlayer url={ytVideoID} onPlay={onPlay} />;

  const isMP4 = url.search(".mp4") !== -1;
  if (isMP4)
    return (
      <VideoPlayer
        url={url}
        poster={poster}
        onPlay={onPlay}
        isVisible={isVisible}
        isFirst={isFirst}
      />
    );

  return null;
};

export default memo(Player);
