import React from "react";

const Structure = (props) => {
  let data = props["structure"],
    { lang } = props;

  if (!data) return null;

  return (
    <div>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: data[lang] }}
      />
    </div>
  );
};

export default Structure;
