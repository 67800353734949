import React, { useEffect } from "react";

const Mentors = (props) => {
  let data = props["mentors"] ? props["mentors"] : {},
    { lang } = props;
  data = Object.values(data);
  useEffect(() => {
    // props.onLockScroll();
    return () => {
      props.onUnlockScroll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mentors-container">
      {data.map((testimonial, index) => (
        <div key={index} className="testimonial">
          <h3>{testimonial.mentor}</h3>
          <span
            dangerouslySetInnerHTML={{ __html: testimonial.text[lang] }}
          ></span>
        </div>
      ))}
    </div>
  );
};

export default Mentors;
