import React, { useState, useRef } from 'react'


const FileInput = props => {
  const hiddenFileInput = useRef(null)
  const handleClick = () => hiddenFileInput.current.click()
  const handleChange = event => {
    const fileUploaded = event.target.files[0]
      , fileSize = fileUploaded.size / 1024 / 1024
    if (fileSize > 5) return props.onError(`${props.inputName}: Archivo con peso mayor a 5Mb`)
    else {
      props.onError('')
      props.onChange(fileUploaded)
    }
  }
  let filename = props.value && props.value.name ? props.value.name : props.description
  return <div>
    <div className='input file-input' onClick={handleClick}>
      {filename.length > 10 ? filename.substring(0, 10) : filename}
    </div>
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept="application/pdf"
    />
  </div>
}

export default FileInput