import React from "react";

const Testimonials = (props) => {
  let data = props["testimonials"] ? props["testimonials"] : [],
    { lang } = props;
  return (
    <div>
      {data.map((testimonial, index) => (
        <div key={index} className="testimonial">
          <span
            dangerouslySetInnerHTML={{ __html: testimonial.text[lang] }}
          ></span>
          <h3>{testimonial.autor}</h3>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
