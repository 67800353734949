import React, { useState, useEffect, useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "./baseComponents/Header";
import Home from "./pages/Home";
import Programs from "./pages/Programs";
import Us from "./pages/Us";
import Location from "./pages/Location";
import Archive from "./pages/Archive";
import Gallery from "./pages/Gallery";

import { getSections } from "./services/sections";

import "react-alice-carousel/lib/alice-carousel.css";
import "./App.css";

let canScroll = true;
const slideRoutes = [
  "/",
  "/nosotros",
  "/residencyprograms",
  "/ubicacion",
  "/archivo",
  "/galeria",
];

const meta = [
    "Art residency with the goal of promoting and incentivising the production of contemporary art.",
    "COBERTIZO is a non-profit residency that opened its doors in 2019.",
    "The International Intensive Program (IIP) aims to promote the diversity of pictorial practices in the international art scene. The program is designed to be an intensive immersion focused on art production.",
    "COBERTIZO is located in Jilotepec, a municipality located on the north of the Estado de México. It is 1 hour 30 minutes away from Mexico City.",
    "Discover the artists that have been part of our AIR",
    "Apply now to our International Art Program.",
  ],
  titles = [
    "COBERTIZO",
    "ABOUT US",
    "PROGRAMS",
    "LOCATION",
    "ARCHIVE",
    "GALLERY",
  ];

const App = (props) => {
  let timeout = null;
  const slideRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0),
    [pageContent, setPageContent] = useState(null),
    [program, setProgram] = useState(undefined),
    [lang, setLang] = useState("es"),
    //, [showPopUp, setShowPopUp] = useState(true)
    [formActive, setFormActive] = useState(false);

  const onUpdateURL = (index) => {
    setActiveIndex(index);
    props.history.push(slideRoutes[index]);
  };
  const onChangeProgram = (program) => {
    setProgram(program);

    if (window.innerWidth < 768) {
      let sections = ["home-mobile", "us-mobile"],
        height = 0;
      height = sections
        .map((key) => document.querySelector(`#mobile .${key}`).clientHeight)
        .reduce((a, b) => a + b);
      window.scrollTo(
        0,
        height - document.getElementById("header_container").clientHeight
      );
    }
  };

  const onChangePage = (page, params = "") => {
    if (slideRef.current && canScroll) {
      slideRef.current.slideTo(page);
      onUpdateURL(page, params);
    }
  };
  const onNext = () => {
    let activeIndex = slideRef.current.state.activeIndex;

    if (activeIndex === pages.length - 1) return (canScroll = true);
    if (slideRef.current) slideRef.current.slideNext();
  };
  const onPrev = () => {
    let activeIndex = slideRef.current.state.activeIndex;
    if (activeIndex === 0) return (canScroll = true);
    if (slideRef.current) slideRef.current.slidePrev();
  };
  const onLockScroll = () => (canScroll = false),
    onUnlockScroll = () => (canScroll = true);

  const onFormActive = () => {
    props.history.push(!formActive ? "/apply" : slideRoutes[activeIndex]);
    setFormActive(!formActive);
  };

  const pages = [
    <Home
      lang={lang}
      onNext={onNext}
      data={pageContent}
      pageIndex={activeIndex}
      onChangePage={onChangePage}
      onChangeProgram={onChangeProgram}
    />,
    <Us lang={lang} onNext={onNext} onPrev={onPrev} data={pageContent} />,
    <Programs
      pathname={props.location.pathname}
      onFormActive={onFormActive}
      lang={lang}
      onNext={onNext}
      onPrev={onPrev}
      data={pageContent}
      program={program}
      onLockScroll={onLockScroll}
      onUnlockScroll={onUnlockScroll}
      onChangeProgram={onChangeProgram}
    />,
    <Location
      lang={lang}
      onNext={onNext}
      onPrev={onPrev}
      data={pageContent}
      program={program}
    />,
    <Archive
      activeIndex={activeIndex}
      lang={lang}
      onNext={onNext}
      onPrev={onPrev}
      data={pageContent}
      onLockScroll={onLockScroll}
      onUnlockScroll={onUnlockScroll}
    />,
    <Gallery
      lang={lang}
      onNext={onPrev}
      onPrev={onPrev}
      data={pageContent}
      onLockScroll={onLockScroll}
      onUnlockScroll={onUnlockScroll}
      pageIndex={activeIndex}
    />,
  ];

  const onSlideChange = (e) => {
    timeout = setTimeout(() => {
      onUpdateURL(slideRef.current.state.activeIndex);
      onChangePage(slideRef.current.state.activeIndex);
    }, 1000);
    setTimeout(() => {
      canScroll = true;
    }, 2000);
  };

  const onResourcesLoaded = () => {
    let urlIndex = slideRoutes
      .map((url, index) => (url === props.location.pathname ? index : null))
      .filter((url) => url);

    urlIndex = urlIndex.length ? urlIndex[0] : 0;

    if (props.location.pathname === "/apply") {
      urlIndex = 2;
      setFormActive(true);
    }

    setActiveIndex(urlIndex);

    /*  const onScroll = e => {
       if (!!isMobile.any()) return
       let { deltaY } = e
       if (canScroll) {
         canScroll = false
         if (deltaY > 10) return onNext()
         else if (deltaY < -10) return onPrev()
         return canScroll = true
       }
     } */
    // window.addEventListener('wheel', onScroll)
    return () => {
      // window.removeEventListener('wheel', onScroll)
      clearTimeout(timeout);
    };
  };

  useEffect(() => {
    let lang = navigator.language || navigator.userLanguage;
    lang = lang.search("es") !== -1 ? "es" : "en";
    setLang(lang);
    getSections()
      .then((res) => {
        // setProgram(Object.keys(res.programas)[1]);
        setPageContent(res);
        onResourcesLoaded();
      })
      .catch((err) => console.log("error", err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pageContent) return null;

  return (
    <React.Fragment>
      <Helmet>
        <title>{titles[activeIndex]}</title>
        <meta name="description" content={meta[activeIndex]} />
      </Helmet>
      <div id="cobertizo">
        <Header
          programs={pageContent.programas}
          activeIndex={activeIndex}
          lang={lang}
          setLang={setLang}
          onChangePage={onChangePage}
          onChangeProgram={onChangeProgram}
        />
        <div id="desktop">
          <AliceCarousel
            animationEasingFunction="ease-in-out"
            animationDuration={2000}
            activeIndex={activeIndex}
            ref={slideRef}
            disableButtonsControls
            disableDotsControls
            disableSlideInfo
            mouseTracking={!canScroll}
            items={pages}
            onSlideChange={onSlideChange}
            infinite={true}
          />
        </div>
        <div id="mobile">
          <Home
            id="home-mobile"
            lang={lang}
            onNext={onNext}
            data={pageContent}
            pageIndex={activeIndex}
            onChangePage={onChangePage}
            onChangeProgram={onChangeProgram}
          />
          <Us
            id="us-mobile"
            lang={lang}
            onNext={onNext}
            onPrev={onPrev}
            data={pageContent}
          />
          <Programs
            mobile={true}
            id="programs-mobile"
            lang={lang}
            onNext={onNext}
            onPrev={onPrev}
            data={pageContent}
            program={program}
            onLockScroll={onLockScroll}
            onUnlockScroll={onUnlockScroll}
            onChangeProgram={onChangeProgram}
          />
          <Location
            id="location-mobile"
            lang={lang}
            onNext={onNext}
            onPrev={onPrev}
            data={pageContent}
            program={program}
          />
          <Archive
            id="archive-mobile"
            lang={lang}
            onNext={onNext}
            onPrev={onPrev}
            data={pageContent}
            onLockScroll={onLockScroll}
            onUnlockScroll={onUnlockScroll}
          />
          <Gallery
            id="gallery-mobile"
            lang={lang}
            onNext={onPrev}
            onPrev={onPrev}
            data={pageContent}
            onLockScroll={onLockScroll}
            onUnlockScroll={onUnlockScroll}
            pageIndex={activeIndex}
          />
        </div>
      </div>
      {/*showPopUp
    ? <div id='popup'>
      <div className='container'>
        <h2>
          {
            lang === 'es'
              ? 'ANUNCIO COVID COBERTIZO'
              : 'COVID UPDATE'
          }
        </h2>
        <p>
          {
            lang === 'es'
              ? 'Debido a la situación mundial de emergencia sanitaria por el COVID-19, Cobertizo se compromete a implementar todas las medidas necesarias para evitar su propagación de acuerdo con los mandatos oficiales. Bajo la misma lógica, Cobertizo ofrece a cada artista una habitación propia con baño individual a los que solo ellxs podrán acceder. Así mismo, los espacios que Cobertizo ofrece a sus artistas son muy amplios (35 hectáreas), tanto el terreno como la casa misma, lo que posibilita mantener una sana distancia sin complicaciones ni necesidad de salir del espacio.'
              : 'Due to the COVID-19 global pandemic, COBERTIZO commits to implement all necessary measures to prevent its spread in accordance with official mandates. COBERTIZO offers each artist their own room with an individual bathroom. The property where COBERTIZO is based is made up of 86 acres which makes it possible to maintain a healthy distance without complications or the need to leave the space.'
          }
        </p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='button' onClick={() => setShowPopUp(false)}>
            <span>{lang === 'es' ? 'Enterado' : 'Ok'}</span>
          </div>
        </div>
      </div>
    </div>
    : null
    */}
    </React.Fragment>
  );
};

export default withRouter(App);

/*






*/
