import React, { useRef, useEffect } from "react";
import ProgramDetail from "./ProgramDetail";
import Testimonials from "./Testimonials";
import Structure from "./Structure";
import element02 from "../../../assets/imgs/home/element_02.png";
import Mentors from "./Mentors";

const MainInfo = (props) => {
  let {
      program,
      onChangeProgram,
      currentProgram,
      programFilters,
      currentFilter,
      setCurrentFilter,
      lang,
      onLockScroll,
      onUnlockScroll,
    } = props,
    contentRef = useRef(null);

  let filters =
    currentProgram === "residence"
      ? programFilters.filter((_, i) => i === 0)
      : programFilters;

  let title = program &&
  program.detail && program.detail.title
    ? program.detail.title
    : { es: "", en: "" };

  let comingSoon = typeof title[lang] === "string" ? title[lang] : "";
  comingSoon = comingSoon.search(/\bNacional\b/) !== -1 || comingSoon.search(/\bNational\b/) !== -1;
  comingSoon = Boolean(comingSoon);

  const renderInfoSection = () => {
    switch (currentFilter) {
      case "structure":
        return <Structure {...program} lang={lang} />;
      case "testimonials":
        return <Testimonials {...program} lang={lang} />;
      case "mentors":
        return (
          <Mentors
            {...program}
            lang={lang}
            onLockScroll={onLockScroll}
            onUnlockScroll={onUnlockScroll}
          />
        );
      default:
        return <ProgramDetail {...program} comingSoon={comingSoon} lang={lang} />;
    }
  };

  useEffect(() => {
    const onScroll = (e) => {
      e.stopPropagation();
    };
    document.getElementById("main-info").addEventListener("scroll", onScroll);
  }, []);

  if (!program) return  <div id="main-info"></div>

  let isScholarship = typeof title[lang] === "string" ? title[lang] : "";
  isScholarship = isScholarship.toUpperCase().search("AYARKUT") !== -1 || isScholarship.search(/\bNacional\b/) !== -1 || isScholarship.search(/\bNational\b/) !== -1;

  title = title[lang].replace(
    "Ayarkut",
    "<span className='font_ayarkut'>Ayarkut</span>"
  );

  const ayakurt = {
    es: "Comité de selección",
    en: "Selection committee",
  };

  const getTextLabel = (option) => {
    if (isScholarship && option.action === "mentors") return ayakurt[lang];
    return option.text[lang];
  };

  return (
    <div id="main-info">
      <div className="program-title">
        <span className="mobile-title">
          {lang === "es" ? "Programa" : "Program"}
        </span>
        <span
          className={`desktop-title ${
            currentProgram === "residence" ? "residence-title" : "ppc-title"
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        ></span>
      </div>
      <div className="program-selector">
        <div
          className={`program-select ${
            currentProgram === "PPC" ? "active" : ""
          }`}
        >
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </div>
        {/*       <div onClick={() => onChangeProgram('residence')} className={`program-select ${currentProgram === 'residence' ? 'active' : ''}`}>
        <span>{lang === 'es' ? 'Residencia' : 'Residency'}</span>
      </div> */}
      </div>
      <div className="program-main-content">
        <div className="program-filters">
          {filters.map(
            (filter) =>
              Boolean(program[filter.action]) && (
                <div
                  key={filter.action}
                  className={`program-filter ${
                    filter.action === currentFilter ? "active" : ""
                  }`}
                  onClick={() => setCurrentFilter(filter.action)}
                >
                  {getTextLabel(filter)}
                </div>
              )
          )}
        </div>
        <div id="content" className="content" ref={contentRef}>
          {renderInfoSection()}
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
